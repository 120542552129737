@tailwind base;
@tailwind components;
@tailwind utilities;

/* --------------------   fonts ------------------- */
/* canela font for headings */
@font-face {
  font-family: "Canela";
  src: url("//db.onlinewebfonts.com/t/4a24899e94d8236f671c1090cd9e068c.eot");
  src: url("//db.onlinewebfonts.com/t/4a24899e94d8236f671c1090cd9e068c.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/4a24899e94d8236f671c1090cd9e068c.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/4a24899e94d8236f671c1090cd9e068c.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/4a24899e94d8236f671c1090cd9e068c.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/4a24899e94d8236f671c1090cd9e068c.svg#Canela")
      format("svg");
}

/* erato for body */

@font-face {
  font-family: "Erato";
  src: url("./fonts/erato/eratolig-webfont.woff2") format("woff2"),
    url("./fonts/erato/eratolig-webfont.woff") format("woff"),
    url("./fonts/erato/eratolig-webfont.ttf") format("truetype"),
    url("./fonts/erato/eratolig-webfont.svg#eratolight") format("svg");

  font-weight: normal;
  font-style: normal;
}

/* @import url("http://fonts.cdnfonts.com/css/cleanvertising");
@import url("http://fonts.cdnfonts.com/css/julius-sans-one");
.font-Cleanvertising {
  font-family: "Cleanvertising", sans-serif;
} */

@import url("http://fonts.cdnfonts.com/css/roboto");
.font-Roboto {
  font-family: "Roboto", sans-serif;
}

/* @import url("http://fonts.cdnfonts.com/css/montserrat");

.font-Montserrat {
  font-family: "Montserrat", sans-serif;
} */

@font-face {
  font-family: "Montserrat";
  src: url("http://fonts.cdnfonts.com/css/montserrat");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Waterfall";
  src: url("./fonts/Waterfall/Waterfall-Regular.woff2") format("woff2"),
    url("./fonts/Waterfall/Waterfall-Regular.ttf") format("truetype");

  font-weight: normal;
  font-style: normal;
}

.font-Canela {
  font-family: "Canela";
}
.font-Julius-Sans {
  font-family: "Julius Sans One", sans-serif;
}
.font-waterfall {
  font-family: "Waterfall";
}

/* --------------------   fonts ------------------- */

.svg {
  @apply fill-gray-500 transform transition duration-500 ease-in-out;
}
.svg:hover {
  @apply scale-110 fill-gray-400;
}

/* css for hero crousel  */

.carousel-open:checked + .carousel-item {
  position: static;

  opacity: 100;
}

.carousel-item {
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}

#carousel-1:checked ~ .carousel-bullet,
#carousel-2:checked ~ .carousel-bullet,
#carousel-3:checked ~ .carousel-bullet {
  color: #2b6cb0;
}

.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}

#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet {
  color: #2b6cb0;
  /*Set to match the Tailwind colour you want the active one to be */
}

/* special underline */

.u-line:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #374151;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.u-line:hover:after {
  width: 100%;
  left: 0;
}

/* box shadow */

.shadow-box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.shadow-box-hover:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* ------------ image box ------------ */
.my-box {
  display: flex;
  height: 0;
  padding-bottom: 100%;
  width: 100%;
}

/* ------ view button   product hover --------- */
.switch {
  position: relative;
}

.div_a {
  position: relative;
  opacity: 1;
  transition: 600ms;
}

.div_hover {
  position: absolute;
  opacity: 0;
  top: 0;
}

.switch:hover .div_hover {
  opacity: 1;
  transition-duration: 300ms;
  transition-timing-function: linear;
  z-index: 11;
}

.carousel-image:hover {
  @apply scale-150;
}

.carousel-item:hover {
  @apply overflow-ellipsis;
}

/* gradiant background */

.hero-background {
  background: rgb(240, 237, 242);
  background: linear-gradient(
    0deg,
    rgba(240, 237, 242, 1) 0%,
    rgba(254, 252, 249, 1) 100%
  );
}

.react-slider__lens .react-slider__imgZoom {
  display: none !important;
}
.eapps-link[style] {
  opacity: 0 !important;
}

@media screen and (max-width: 880px) {
  .react-slider__imgZoom {
    display: none;
  }
  .react-slider__lens {
    display: none;
  }
}

/*-------------- slick Slider ------------- */

.header-text-bg {
  background-color: #00000063;
  box-shadow: 0px 0px 123px 31px rgba(0, 0, 0, 0.67);
  -webkit-box-shadow: 0px 0px 123px 31px rgba(0, 0, 0, 0.67);
  -moz-box-shadow: 0px 0px 123px 31px rgba(0, 0, 0, 0.67);
}

.header-text-bg2 {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 6, 0.2) 11%,
    rgba(0, 0, 1, 0.35) 22%,
    rgba(0, 0, 0, 0.35) 80%,
    rgba(0, 0, 0, 0.20354079131652658) 90%,
    rgba(0, 0, 0, 0) 100%
  );
}
